<template>
  <v-app>
    <v-app-bar
      app
      dark
    >
      <div class="d-flex align-center headline text-uppercase font-weight-bold">
        <v-img
          alt="RTANC Logo"
          class="shrink mr-2"
          contain
          src="https://raw.githubusercontent.com/RTANC/OnlineExam/master/css/Images/logo_v.png"
          transition="scale-transition"
          width="40"
        />

        <span class="hidden-sm-and-down">royal thai army nursing college </span>
        <span class="d-md-none">RTANC</span>
      </div>

      <v-spacer></v-spacer>

      <v-btn text :to="{ path: '/profile' }" v-if="$store.getters.user.idToken != null && $store.getters.user.userType != null">
        <span class="mr-2 hidden-sm-and-down">{{ $store.getters.user.fullname }}</span>
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
      <v-btn color="error" icon @click="logout" v-if="$store.getters.user.idToken != null && $store.getters.user.userType != null"><v-icon>mdi-exit-to-app</v-icon></v-btn>
    </v-app-bar>

    <v-main>
      <v-slide-x-reverse-transition mode="out-in">
        <router-view/>
      </v-slide-x-reverse-transition>
    </v-main>

    <v-bottom-navigation app v-model="value" grow mandatory color="green darken-3" v-if="$store.getters.user.idToken != null && $store.getters.user.userType != null">
      <v-btn value="package" height="100%" :to="{ name: 'PackagesInbox' }" active.sync color="transparent">
        <span>พัสดุ</span>

        <v-icon>mdi-package-variant-closed</v-icon>
      </v-btn>

      <v-btn value="home" height="100%" :to="{ name: 'Home' }" active.sync  color="transparent">
        <span>หน้าแรก</span>

        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn value="e-Services" height="100%" :to="{ path: '/e-Services' }" active.sync  color="transparent">
        <span>e-Services</span>

        <v-icon>mdi-form-select</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import { logouts } from '@/services/logouts'
export default {
  name: 'App',
  data: () => ({
    value: 'home'
  }),
  methods: {
    async logout () {
      try {
        // eslint-disable-next-line no-undef
        const auth2 = gapi.auth2.getAuthInstance()
        await auth2.signOut()
        logouts(this.$store.getters.user.sessId)
        this.$store.dispatch('logout')
        this.$router.push('/')
      } catch (error) {
        console.log(error)
        this.$router.push('/')
      }
    }
  }
}
</script>
