import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/RegisterView.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/packages',
    name: 'Packages',
    component: () => import('@/views/packages/Index.vue')
  },
  {
    path: '/packages/submit/:id',
    name: 'PackagesSubmit',
    component: () => import('@/views/packages/Submit.vue')
  },
  {
    path: '/packages/inbox',
    name: 'PackagesInbox',
    component: () => import('@/views/packages/Inbox.vue')
  },
  {
    path: '/e-Services',
    name: 'e-Services',
    component: () => import('@/views/e-Services/Index.vue')
  },
  {
    path: '/e-Services/qrcode',
    name: 'e-Services',
    component: () => import('@/views/e-Services/qrcode.vue')
  },
  {
    path: '/it/inbox',
    name: 'ItInbox',
    component: () => import('@/views/it/inbox/Index.vue')
  },
  {
    path: '/it/inbox/borrow/forms',
    name: 'ItInboxBorrowForms',
    component: () => import('@/views/it/inbox/BorrowForms.vue')
  },
  {
    path: '/it/inbox/borrow/forms/:id/revert',
    name: 'ItInboxBorrowFormRevert',
    component: () => import('@/views/it/borrow/Form.vue')
  },
  {
    path: '/it/users/',
    name: 'ItUsers',
    component: () => import('@/views/it/users/index.vue')
  },
  {
    path: '/it/users/:id',
    name: 'ItUsersViewProfile',
    component: () => import('@/views/it/users/ViewProfile.vue')
  },
  {
    path: '/it/tools/emailgenerator',
    name: 'EmailGeneratorTools',
    component: () => import('@/views/it/tools/EmailGenerator.vue')
  },
  {
    path: '/it/devices',
    name: 'ItDevices',
    component: () => import('@/views/it/devices/Index.vue')
  },
  {
    path: '/it/devices/bulkUpload',
    name: 'ItDevicesBulkUpload',
    component: () => import('@/views/it/devices/BulkUpload.vue')
  },
  {
    path: '/it/devices/:id',
    name: 'ItDevicesForm',
    component: () => import('@/views/it/devices/Form.vue')
  },
  {
    path: '/it/e-services/borrow/forms',
    name: 'ItEserviceBorrowForms',
    component: () => import('@/views/it/borrow/Forms.vue')
  },
  {
    path: '/it/e-services/borrow/forms/:id',
    name: 'ItEserviceBorrowForm',
    component: () => import('@/views/it/borrow/Form.vue')
  },
  {
    path: '/it/e-services/borrow/devices',
    name: 'ItEserviceBorrowDevices',
    component: () => import('@/views/it/borrow/Devices.vue')
  },
  {
    path: '/bids/announcements',
    name: 'bidAnnouncements',
    component: () => import('@/views/bids/BidAnnounce.vue')
  },
  {
    path: '/bids/announcements/:id',
    name: 'bidAnnouncementForm',
    component: () => import('@/views/bids/BidAnnounceForm.vue')
  },
  {
    path: '/evaluates/grade/',
    name: 'EvaluateGrade',
    component: () => import('@/views/evaluates/Grade.vue')
  },
  {
    path: '/e-billing/',
    name: 'e-BillingIndex',
    component: () => import('@/views/e-Billing/index.vue')
  },
  {
    path: '/e-billing/:id',
    name: 'e-BillingForm',
    component: () => import('@/views/e-Billing/Form.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
